import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { H2, NamedLink } from '../../components';

import css from './SectionTopCities.module.css';
import city1 from '../../assets/top-cities/1.png';
import city2 from '../../assets/top-cities/2.png';
import city3 from '../../assets/top-cities/3.png';
import city4 from '../../assets/top-cities/4.png';
import { ReactComponent as EyeSVG } from '../../assets/svg/eye.svg';
import { ReactComponent as ArrowRightSVG } from '../../assets/svg/arrow-right.svg';

const topCities = [
  {
    name: 'Muscat City',
    image: city1,
    searchPath:
      'address=Muscat%2C%20Oman&bounds=23.64556994907253%2C58.62085962441947%2C23.52007955520515%2C58.22817290880074',
  },
  {
    name: 'Nizwa City',
    image: city2,
    searchPath:
      'address=Nizwa%2C%20Oman&bounds=22.99053395012498%2C57.56475298775734%2C22.83430082191342%2C57.50445372159854',
  },
  {
    name: 'Salalah',
    image: city3,
    searchPath:
      'address=Salalah%2C%20Oman&bounds=17.13137771842349%2C54.24073803253341%2C16.97257679598338%2C53.98411264196135',
  },
  {
    name: 'Sohar',
    image: city4,
    searchPath:
      'address=Sohar%2C%20Oman&bounds=24.46724699093584%2C56.84087982210403%2C24.2134652830617%2C56.58440921590914',
  },
];

const SectionTopCities = props => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  return (
    <article className={css.root}>
      <H2 as="h2" className={css.articleHeader}>
        Explore Top Cities
      </H2>

      <section className={classNames(css.gridContainer)}>
        {/* {Array.from({ length: 4 }, (_, index) => index + 1).map(e => ( */}
        {topCities.map(topCity => (
          <div key={topCity.name} className={css.gridContent}>
            <div
              className={css.image}
              style={{ backgroundImage: `url(${topCity.image})` }}
              onClick={() => {
                history.push(`/s?${topCity.searchPath}`);
              }}
            >
              <div className={css.overlay}>
                <EyeSVG />
              </div>
            </div>

            <div className={css.content}>
              <div>{topCity.name}</div>
              <div>
                <NamedLink name="SearchPage" to={{ search: `${topCity.searchPath}` }}>
                  <span>Explore</span>
                  <ArrowRightSVG />
                </NamedLink>
              </div>
            </div>
          </div>
        ))}
      </section>
    </article>
  );
};

export default SectionTopCities;
