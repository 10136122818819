import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { createResourceLocatorString } from '../../util/routes';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { H2, PrimaryButtonInline } from '../../components';

import css from './SectionPopularSearches.module.css';
import search1 from '../../assets/popular-searches/1.png';
import search2 from '../../assets/popular-searches/2.png';
import search3 from '../../assets/popular-searches/3.png';
import search4 from '../../assets/popular-searches/4.png';
import search5 from '../../assets/popular-searches/5.png';

const popularSearches = [
  {
    name: 'Furniture',
    image: search1,
    searchParams: {
      pub_category: 'homeDecor',
      pub_subCategory: 'furniture',
    },
  },
  {
    name: 'Lamps and lighting',
    image: search2,
    searchParams: {
      pub_category: 'homeDecor',
      pub_subCategory: 'lampsAndLighting',
    },
  },
  {
    name: 'AC',
    image: search3,
    searchParams: {
      pub_category: 'repairAndServices',
      pub_subCategory: 'ac',
    },
  },
  {
    name: 'Rental',
    image: search4,
    searchParams: {
      pub_category: 'rental',
    },
  },
  {
    name: 'Construction',
    image: search5,
    searchParams: {
      pub_category: 'construction',
    },
  },
];

const SectionPopularSearches = props => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  return (
    <article className={css.root}>
      <H2 as="h2" className={css.articleHeader}>
        Popular Searches
      </H2>

      <section className={classNames(css.gridContainer)}>
        {/* {Array.from({ length: 5 }, (_, index) => index + 1).map(e => ( */}
        {popularSearches.map(popularSearch => (
          <div key={popularSearch.name} className={css.gridContent}>
            <div
              className={css.image}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration,
                    {},
                    popularSearch.searchParams
                  )
                );
              }}
            >
              <img src={popularSearch.image} alt={popularSearch.name} />
            </div>

            <div className={css.content}>
              <div>{popularSearch.name}</div>
              <div>
                <PrimaryButtonInline
                  onClick={() => {
                    history.push(
                      createResourceLocatorString(
                        'SearchPage',
                        routeConfiguration,
                        {},
                        popularSearch.searchParams
                      )
                    );
                  }}
                >
                  Enquire Now
                </PrimaryButtonInline>
              </div>
            </div>
          </div>
        ))}
      </section>
    </article>
  );
};

export default SectionPopularSearches;
