import React from 'react';
import { useHistory } from 'react-router-dom';
import { H4, NamedLink, PrimaryButtonInline } from '../../components';

import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import css from './SectionHeroCustom.module.css';

import card1 from '../../assets/hero/1.png';
import card2 from '../../assets/hero/2.png';
import card3 from '../../assets/hero/3.png';
import card4 from '../../assets/hero/4.png';
import heroBanner from '../../assets/hero/hero-banner.png';
import { ReactComponent as ArrowRightSVG } from '../../assets/svg/arrow-right.svg';

const cards = [
  {
    image: card1,
    name: 'Construction',
    searchKeyword: 'construction',
  },
  {
    image: card2,
    name: 'Business Service',
    searchKeyword: 'businessService',
  },
  {
    image: card3,
    name: 'Home Decor',
    searchKeyword: 'homeDecor',
  },
  {
    image: card4,
    name: 'Repair and Services',
    searchKeyword: 'repairAndServices',
  },
];

const SectionHeroCustom = props => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  return (
    <article className={css.root}>
      <section
        className={css.sliderContainer}
        style={{
          backgroundImage: `url(${heroBanner})`,
        }}
        onClick={() => {
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {}));
        }}
      >
        <div className={css.sliderContent}>
          <H4>Looking for trusted supplier</H4>
          <div>
            <PrimaryButtonInline className={css.getBestDealsButton}>
              Get Best Deals
            </PrimaryButtonInline>
          </div>
        </div>
      </section>

      <section className={css.cardsContainer}>
        {/* {Array.from({ length: 4 }, (_, index) => index + 1).map(e => ( */}
        {cards.map(card => (
          <div
            key={card.name}
            className={css.cardContainer}
            style={{
              backgroundImage: `url(${card.image})`,
            }}
          >
            <div
              className={css.cardContent}
              onClick={() => {
                const params = { pub_category: card.searchKeyword };
                history.push(
                  createResourceLocatorString('SearchPage', routeConfiguration, {}, params)
                );
              }}
            >
              <H4>{card.name}</H4>
              <div>
                {/* <NamedLink name="SearchPage"> */}
                <span>Explore</span>
                <ArrowRightSVG />
                {/* </NamedLink> */}
              </div>
            </div>
          </div>
        ))}
      </section>
    </article>
  );
};

export default SectionHeroCustom;
