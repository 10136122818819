import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { createResourceLocatorString } from '../../util/routes';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import css from './SectionCategories.module.css';
import category1 from '../../assets/categories/1.png';
import category2 from '../../assets/categories/2.png';
import category3 from '../../assets/categories/3.png';
import category4 from '../../assets/categories/4.png';
import category5 from '../../assets/categories/5.png';
import category6 from '../../assets/categories/6.png';
import category7 from '../../assets/categories/7.png';
import category8 from '../../assets/categories/8.png';
import category9 from '../../assets/categories/9.png';
import category10 from '../../assets/categories/10.png';
import category11 from '../../assets/categories/11.png';
import category12 from '../../assets/categories/12.png';
import category13 from '../../assets/categories/13.png';
import category14 from '../../assets/categories/14.png';
import category15 from '../../assets/categories/15.png';
import category16 from '../../assets/categories/16.png';
import category17 from '../../assets/categories/17.png';
import category18 from '../../assets/categories/18.png';
import category19 from '../../assets/categories/19.png';
import category20 from '../../assets/categories/20.png';

const categories = [
  {
    name: 'Pipe',
    image: category1,
    searchParam: {
      pub_category: 'construction',
      pub_subCategory: 'buildingMaterials',
      pub_subSubCategory: 'pipes',
    },
  },
  {
    name: 'Brick',
    image: category2,
    searchParam: {
      pub_category: 'construction',
      pub_subCategory: 'buildingMaterials',
      pub_subSubCategory: 'brick',
    },
  },
  {
    name: 'Steel',
    image: category3,
    searchParam: {
      pub_category: 'construction',
      pub_subCategory: 'buildingMaterials',
      pub_subSubCategory: 'steel',
    },
  },
  {
    name: 'Cement',
    image: category4,
    searchParam: {
      pub_category: 'construction',
      pub_subCategory: 'buildingMaterials',
      pub_subSubCategory: 'cement',
    },
  },
  {
    name: 'Stone',
    image: category5,
    searchParam: {
      pub_category: 'construction',
      pub_subCategory: 'buildingMaterials',
      pub_subSubCategory: 'stone',
    },
  },
  {
    name: 'Sand',
    image: category6,
    searchParam: {
      pub_category: 'construction',
      pub_subCategory: 'buildingMaterials',
      pub_subSubCategory: 'sand',
    },
  },
  {
    name: 'False Ceiling',
    image: category7,
    searchParam: {
      pub_category: 'construction',
      pub_subCategory: 'interior',
      pub_subSubCategory: 'falseCeiling',
    },
  },
  {
    name: 'Block Making Machines',
    image: category8,
    searchParam: {
      pub_category: 'construction',
      pub_subCategory: 'constructionMachines',
      pub_subSubCategory: 'blockMakingMachines',
    },
  },
  {
    name: 'TMT Bars',
    image: category9,
    searchParam: {
      pub_category: 'construction',
      pub_subCategory: 'steelBarsRodsPlatesAndSheets',
      pub_subSubCategory: 'tmtBars',
    },
  },
  {
    name: 'Accounting',
    image: category10,
    searchParam: {
      pub_category: 'businessService',
      pub_subCategory: 'accounting',
    },
  },
  {
    name: 'Business Legal Setup',
    image: category11,
    searchParam: {
      pub_category: 'businessService',
      pub_subCategory: 'businessLegalSetup',
    },
  },
  {
    name: 'Car Rental',
    image: category12,
    searchParam: {
      pub_category: 'rental',
      pub_subCategory: 'carRental',
    },
  },
  {
    name: 'Machinery',
    image: category13,
    searchParam: {
      pub_category: 'rental',
      pub_subCategory: 'machinery',
    },
  },
  {
    name: 'Lamps & Lighting',
    image: category14,
    searchParam: {
      pub_category: 'homeDecor',
      pub_subCategory: 'lampsAndLighting',
    },
  },
  {
    name: 'Furniture',
    image: category15,
    searchParam: {
      pub_category: 'homeDecor',
      pub_subCategory: 'furniture',
    },
  },
  {
    name: 'Kitchen & Dinning',
    image: category16,
    searchParam: {
      pub_category: 'homeDecor',
      pub_subCategory: 'kitchenAndDining',
    },
  },
  {
    name: 'CCTV Camera Repair & Service',
    image: category17,
    searchParam: {
      pub_category: 'repairAndServices',
      pub_subCategory: 'cctvCamera',
    },
  },
  {
    name: 'Mobile Phone Repair & Service',
    image: category18,
    searchParam: {
      pub_category: 'repairAndServices',
      pub_subCategory: 'mobilePhone',
    },
  },
  {
    name: 'Laptop & Computer Repair & Service',
    image: category19,
    searchParam: {
      pub_category: 'repairAndServices',
      pub_subCategory: 'laptop',
    },
  },
  {
    name: 'Copywriting',
    image: category20,
    searchParam: {
      pub_category: 'businessService',
      pub_subCategory: 'copywriting',
    },
  },
];

const SectionCategories = props => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  return (
    <article className={css.root}>
      <section className={classNames(css.root, css.flexBox)}>
        {/* {Array.from({ length: 10 }, (_, index) => index + 1).map(e => ( */}
        {categories.slice(0, 10).map(category => (
          <div key={category.name} className={css.flexContent}>
            <div
              className={css.image}
              style={{
                backgroundImage: `url(${category.image})`,
              }}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration,
                    {},
                    category.searchParam
                  )
                );
              }}
            ></div>
            <div className={css.content}>{category.name}</div>
          </div>
        ))}
      </section>

      <section className={classNames(css.root, css.flexBox)}>
        {/* {Array.from({ length: 10 }, (_, index) => index + 1).map(e => ( */}
        {categories.slice(10, 20).map(category => (
          <div key={category.name} className={css.flexContent}>
            <div
              className={css.image}
              style={{
                backgroundImage: `url(${category.image})`,
              }}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration,
                    {},
                    category.searchParam
                  )
                );
              }}
            ></div>
            <div className={css.content}>{category.name}</div>
          </div>
        ))}
      </section>
    </article>
  );
};

export default SectionCategories;
