import React from 'react';
import classNames from 'classnames';
import loadable from '@loadable/component';
import { compose } from 'redux';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bool, object } from 'prop-types';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import { ASSET_NAME } from './LandingPage.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';

import { useConfiguration } from '../../context/configurationContext';

// import FallbackPage from './FallbackPage';
import { LayoutSingleColumn, Page } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import SectionTopCities from '../../components/SectionTopCities/SectionTopCities';
import SectionCategories from '../../components/SectionCategories/SectionCategories';
import SectionHeroCustom from '../../components/SectionHeroCustom/SectionHeroCustom';
import SectionHowItWorks from '../../components/SectionHowItWorks/SectionHowItWorks';
import SectionTrustedPartner from '../../components/SectionTrustedPartner/SectionTrustedPartner';
import SectionPopularSearches from '../../components/SectionPopularSearches/SectionPopularSearches';
import SectionRelatedArticles from '../../components/SectionRelatedArticles/SectionRelatedArticles';

// Old Landing Page
import SectionBanner from '../../components/SectionBanner/SectionBanner';
import SectionJoinUs from '../../components/SectionJoinUs/SectionJoinUs';
import SectionSuppliers from '../../components/SectionSuppliers/SectionSuppliers';
import SectionDescriptions from '../../components/SectionDescriptions/SectionDescriptions';
import SectionClientRequirements from '../../components/SectionClientRequirements/SectionClientRequirements';

import css from './LandingPage.module.css';

// These are shared classes.
// Use these to have consistent styles between different section components
// E.g. share the same title styles
const DEFAULT_CLASSES = {
  sectionDetails: css.sectionDetails,
  title: css.title,
  description: css.description,
  ctaButton: css.ctaButton,
  blockContainer: css.blockContainer,
};

// const PageBuilder = loadable(() =>
//   import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
// );

export const LandingPageComponent = props => {
  const intl = useIntl();
  const config = useConfiguration();
  const {
    pageAssetsData,
    inProgress,
    error,
    scrollingDisabled,
    listings,
    onManageDisableScrolling,
  } = props;
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { marketplaceName });
  const { sections = [], meta = {} } = pageAssetsData?.[camelize(ASSET_NAME)]?.data || {};
  let sectionCarousel = sections[2];

  if (sectionCarousel) {
    sectionCarousel.blocks = sectionCarousel?.blocks?.map(block => {
      delete block?.text;
      delete block?.title;
      return block;
    });
  }

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        mainColumnClassName={classNames(css.layoutWrapperMain, css.landingPageLayoutMain)}
        topbar={<TopbarContainer customAnimation={true} />}
        footer={<FooterContainer />}
      >
        <SectionHeroCustom />
        <SectionCategories />
        <SectionTopCities />
        <SectionPopularSearches />
        <SectionRelatedArticles />
        <div className={css.responsiveContainer}>
          <SectionHowItWorks />
          <SectionTrustedPartner />
        </div>
        {/* <SectionDescriptions /> */}

        {/* <SectionBanner />
        <div className={css.responsiveContainer}>
          <SectionSuppliers />
          <SectionTrustedPartner />
          <SectionJoinUs />
          <SectionHowItWorks />
          <SectionClientRequirements />
        </div> */}
      </LayoutSingleColumn>
    </Page>

    // <PageBuilder
    //   pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
    //   inProgress={inProgress}
    //   error={error}
    //   fallbackPage={<FallbackPage error={error} />}
    // />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error, scrollingDisabled: isScrollingDisabled(state) };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
