import React from 'react';

import { H2, H5 } from '../../components';

import css from './SectionHowItWorks.module.css';
import { ReactComponent as RequestQuotesSVG } from '../../assets/svg/request-quotes.svg';
import { ReactComponent as SecureYourOrderSVG } from '../../assets/svg/secure-your-order.svg';
import { ReactComponent as ReceiveTailoredQuotesSVG } from '../../assets/svg/receive-tailored-quotes.svg';
import { ReactComponent as ExploreTrustedSuppliersSVG } from '../../assets/svg/explore-trusted-suppliers.svg';

const SectionHowItWorks = props => {
  return (
    <article className={css.root}>
      <H2 as="h2" className={css.articleHeader}>
        How It Works
      </H2>
      <p className={css.articlePara}>Streamlining Your Purchase Journey</p>
      <section className={css.gridContainer}>
        <div className={css.container}>
          <ExploreTrustedSuppliersSVG />
          <H5 as="h4">Explore Trusted Suppliers</H5>
          <p>Find top-rated providers quickly and easily.</p>
        </div>
        <div className={css.container}>
          <RequestQuotesSVG />
          <H5 as="h4">Contact or Request Quotes</H5>
          <p>Reach out to suppliers directly or create a custom Request for Quote (RFQ).</p>
        </div>
        <div className={css.container}>
          <ReceiveTailoredQuotesSVG />
          <H5 as="h4">Receive Tailored Quotes</H5>
          <p>Receive quotes that match your specific needs and budget.</p>
        </div>

        {/* <div className={css.container}>
          <SecureYourOrderSVG />
          <H5 as="h4">Secure Your Order</H5>
          <p>
            Finalize your choice, and we'll ensure prompt and enjoy seamless fast, reliable
            delivery.
          </p>
        </div> */}
      </section>
    </article>
  );
};

export default SectionHowItWorks;
