import React from 'react';

import { H2, H5 } from '../../components';

import css from './SectionTrustedPartner.module.css';
import { ReactComponent as VastNetworkSVG } from '../../assets/svg/vast-network.svg';
import { ReactComponent as ProductRangeSVG } from '../../assets/svg/product-range.svg';
import { ReactComponent as SeamlessCommunicationSVG } from '../../assets/svg/seamless-communication.svg';

const SectionTrustedPartner = props => {
  return (
    <article className={css.root}>
      <H2 as="h2" className={css.articleHeader}>
        Your Trusted Partner
      </H2>
      <p className={css.articlePara}>
        Your Trusted Partner in Growth - Bridging Businesses with Opportunities, Suppliers, and
        Success
      </p>
      <section className={css.gridContainer}>
        <div className={css.container}>
          <VastNetworkSVG />
          <H5 as="h4">Vast Network of Suppliers</H5>
          <p>
            Connect with a wide array of verified suppliers offering materials across multiple
            categories, ensuring you find exactly what you need.
          </p>
        </div>
        <div className={css.container}>
          <ProductRangeSVG />
          <H5 as="h4">Comprehensive Product Range</H5>
          <p>
            Explore an extensive catalog of products, from raw materials to finished goods, all in
            one convenient place.
          </p>
        </div>
        <div className={css.container}>
          <SeamlessCommunicationSVG />
          <H5 as="h4">Seamless Communication</H5>
          <p>
            Contact suppliers directly through our platform to discuss specifications, negotiate
            prices, and establish long-term relationships.
          </p>
        </div>
      </section>
    </article>
  );
};

export default SectionTrustedPartner;
