import React from 'react';
import classNames from 'classnames';

import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { H2, NamedLink } from '../../components';

import css from './SectionRelatedArticles.module.css';
import article1 from '../../assets/related-articles/1.png';
import article2 from '../../assets/related-articles/2.png';
import article3 from '../../assets/related-articles/3.png';
import { ReactComponent as ArrowRightSVG } from '../../assets/svg/arrow-right.svg';

const SectionRelatedArticles = props => {
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const { intl } = props;

  const relatedArticles = [
    {
      image: article1,
      cmsPath: 'blog-1',
      description: intl.formatMessage({
        id: 'SectionRelatedArticles.Blog1',
      }),
    },
    {
      image: article2,
      cmsPath: 'blog-2',
      description: intl.formatMessage({
        id: 'SectionRelatedArticles.Blog2',
      }),
    },
    {
      image: article3,
      cmsPath: 'blog-1',
      description: intl.formatMessage({
        id: 'SectionRelatedArticles.Blog1',
      }),
    },
  ];

  return (
    <article className={css.root}>
      <H2 as="h2" className={css.articleHeader}>
        Related Articles
      </H2>

      <section className={classNames(css.gridContainer)}>
        {/* {Array.from({ length: 3 }, (_, index) => index + 1).map(e => ( */}
        {relatedArticles.map(relatedArticle => (
          <div key={relatedArticle.image} className={css.gridContent}>
            <div
              className={css.image}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'CMSPage',
                    routeConfiguration,
                    { pageId: relatedArticle.cmsPath },
                    {}
                  )
                );
              }}
            >
              <img src={relatedArticle.image} alt={relatedArticle.name} />
            </div>

            <div className={css.content}>
              <p>{relatedArticle.description}</p>
              <div>
                <NamedLink name="CMSPage" params={{ pageId: relatedArticle.cmsPath }}>
                  <span>Explore</span>
                  <ArrowRightSVG />
                </NamedLink>
              </div>
            </div>
          </div>
        ))}
      </section>
    </article>
  );
};

export default compose(injectIntl)(SectionRelatedArticles);
